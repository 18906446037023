import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import {
  faVirus,
  faWandSparkles,
  faHandFist,
  faBriefcaseMedical,
  faSkull
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Tab, Tabs } from 'react-bootstrap';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesDreamSnow: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Snow Stomper</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_yeti.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Snow Stomper</h1>
          <h2>
            Dream Realm Snow Stomper guide that explains the boss mechanics and
            how to beat it!
          </h2>
          <p>
            Last updated: <strong>13/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Snow Stomper" />
        <StaticImage
          src="../../../images/afk/generic/boss_snow.webp"
          alt="Guides"
        />
        <p>
          <strong>Snow Stomper</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey. In this guide we will go
          over the boss mechanics and the best characters and teams to use
          against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 2 Teams (Pre-Endless)" />
        <p>
          In Season 2 it will take a few weeks to unlock Endless mode and due to
          how True Damage works pre-Endless (it being weaker than normal
          damage), the meta will most likely look slightly different compared to
          the established Season 1 teams. Also, Season 2 comes with brand new
          Artifacts that also can have a big impact on the meta teams.
        </p>
        <p>
          We will add the teams that work best for us every rotation here soon!
        </p>
        <SectionHeader title="Season 1 Teams (Endless)" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #1 - Best Team (Whale) Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #1 - Best Team
              (Whale) Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/snow_1.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> can
                  be used as an alternative carry
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required),
              </li>
              <li>
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                acts as the secondary damage dealer and offers shields +
                seasonal buffs to the team
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="korin" enablePopover /> can
                  be used as an alternative, still providing damage + shields
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> acts
                as the tank, debuffer and secondary damage dealer,
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as an alternative to Thoran{' '}
                  <strong>(Supreme, EX +8</strong> minimum investment required
                  so she can survive).
                </li>
              </ul>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> has the best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #2 - Alt Team Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #2 - Alt Team
              Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/snow_2.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is the
                secondary carry,
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="korin" enablePopover /> can
                  be used as an alternative to Odie.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required),
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> acts
                as the tank, debuffer and secondary damage dealer,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> has the best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #3 - No Celehypo Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #3 - No
              Celehypo Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/snow_3.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="vala" enablePopover /> is the
                secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
                is the secondary carry and her summons allow you to cheese the
                boss icicles,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as debuffer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="temesia" enablePopover /> acts
                as a self-healing tank and secondary damage dealer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Quickblade Spell" /> best in slot for damage.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Boss Mechanics" />
        <p>
          The boss will start by attacking the closest character with a melee
          attack or ranged attack and then switch to melee attacks, pausing to
          use his AOE Breath attack from time to time. 10s into the battle, Snow
          Stomper summons a <strong>Blizzard</strong> that lasts for 20s, after
          which there will be a 10s cooldown before he casts it again, repeating
          this process 3 times over the course of the fight.
        </p>
        <p>
          After summoning the Blizzard, Snow Stomper{' '}
          <strong>freezes the unit with the highest accumulated damage</strong>,
          dealing damage over time until the snowman is destroyed. Sometimes,
          the boss will also attack the healthiest character, knocking them down
          and interrupting their actions for 1s.
        </p>
        <p>
          This stage does feature skill channeling interruption, so{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          gets very little uptime and we are forced to seek alternate ways of
          healing our team while maintaining offensive buffs.
        </p>
        <p>
          Characters who have any kind of like drain are advantageous, though at
          Supreme+ you can get away with simply running top dps carries such as
          <AFKCharacter mode="inline" slug="marilee" enablePopover /> by
          themselves, relying on{' '}
          <AFKCharacter mode="inline" slug="thoran" enablePopover />,{' '}
          <AFKCharacter mode="inline" slug="shakir" enablePopover /> or{' '}
          <AFKCharacter mode="inline" slug="reinier" enablePopover /> to give
          them just a little bit of extra survivability as their health slowly
          decreases over the course of the fight, resetting the fight if someone
          happens to die early.
        </p>
        <SectionHeader title="Action Summary" />
        <p>
          Snow Stomper takes about <strong>32</strong> actions in the span of 90
          seconds (the full length of the battle - if you survive through all of
          it that is). Here's a list of all possible actions:
        </p>
        <ul>
          <li>Melee attack,</li>
          <li>Ranged attack / Bleeding Ranged attack,</li>
          <li>AOE Breath attack (6x),</li>
          <li>Knockdown attack (4x),</li>
          <li>Snowman freeze attack (5x),</li>
          <li>AOE blizzard cast (3x).</li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Character info" />
        <p>
          This section will be updated soon with Seasonal information, so we
          moved it at the bottom of the guide for the time being!
        </p>
        <div className="dream-group">
          <div className="dream-role">
            <h5 className="debuffer">
              <FontAwesomeIcon icon={faVirus} width="18" /> Debuffer
            </h5>
            <div className="dream-inside">
              <p>
                Anyone who inflicts debuffs that decrease enemy defenses or
                increase enemy damage taken. There are other debuffs too, but
                we’ll focus on those that directly contribute to team damage
                output the most.
              </p>
              <p className="with-line gold">
                <strong className="gold">Best in slot</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="reinier" enablePopover />{' '}
                  (+25% Damage Taken at{' '}
                  <strong className="mythic">[Mythic+]</strong>)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="kruger" enablePopover />{' '}
                  (40% Phys DEF, +40% Phys Damage Taken)
                </li>
              </ul>
              <p className="with-line silver">
                <strong className="silver">Viable alternatives</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="thoran" enablePopover />{' '}
                  (+17% Damage Taken)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lyca" enablePopover /> (up
                  to -37% Phys DEF)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> (+10%
                  Damage Taken)
                </li>
              </ul>
              <p className="with-line bronze">
                <strong className="bronze">Honorable mentions</strong>
              </p>
              <p>
                Not 100% uptime, low debuff value/not the best option or poor
                faction bonus synergy.
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="eironn" enablePopover />{' '}
                  (-40% Magic DEF - top tier debuff, but the current pool of
                  single target magic DPS carries is not good enough to make him
                  worth running)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="brutus" enablePopover />{' '}
                  (-30% Phys DEF - you already use Kruger for this)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="carolina" enablePopover />{' '}
                  (-15% Magic DEF)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="walker" enablePopover />{' '}
                  (-10% Phys DEF, -10 Crit Resist)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cassadee" enablePopover />{' '}
                  (-20% Magic DEF at{' '}
                  <strong className="supreme">[Supreme+]</strong>)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="fay" enablePopover /> (-10%
                  Phys or Magic DEF depending on enemy damage type)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="kafra" enablePopover />{' '}
                  (-15% Phys DEF)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="seth" enablePopover /> (-15%
                  Phys DEF at 67% boss health, -30% Phys DEF at 34% boss health)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="temesia" enablePopover />{' '}
                  (-6% Phys DEF)
                </li>
              </ul>
            </div>
          </div>
          <div className="dream-role">
            <h5 className="buffer">
              <FontAwesomeIcon icon={faWandSparkles} width="18" /> Buffer
            </h5>
            <div className="dream-inside">
              <p>
                Anyone who provides buffs that increase team damage output.
                We’ll focus on offensive buffs, defensive buffs are less
                important if you can already stay alive.
              </p>
              <p className="with-line gold">
                <strong className="gold">Best in slot</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="shakir" enablePopover /> (25
                  Haste, [30% ATK at EX +10], uptime tied to ultimate – doubles
                  as tank; moves around a lot, might be challenging to keep his
                  aura where you want due to lack of manual play -{' '}
                  <strong className="mythic">[Mythic+]</strong> and EX+10
                  required)
                </li>
              </ul>
              <p className="with-line silver">
                <strong className="silver">Viable alternatives</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> (10%
                  ATK to team, 10% ATK to single ally)
                </li>
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="smokey-and-meerky"
                    enablePopover
                  />{' '}
                  (20% ATK, 30 Haste – doubles as healer, but gets interrupted
                  by Blizzard)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lyca" enablePopover /> (40
                  ATK SPD, teamwide 50% uptime - decent with Odie to help build
                  up his damage)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cassadee" enablePopover />{' '}
                  (Her buff acts as a Follow-up attack, high single target
                  uptime, teamwide uptime tied to ultimate)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover /> (40
                  Haste at <strong className="mythic">Mythic+</strong> – doubles
                  as healer)
                </li>
              </ul>
              <p className="with-line bronze">
                <strong className="bronze">Honorable mentions</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="fay" enablePopover /> (14%
                  ATK, 15 ATK SPD, uptime tied to ultimate, unreliable targeting
                  – doubles as healer)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover /> (30
                  Haste at <strong className="supreme">[Supreme+]</strong>
                  uptime tied to healing skill – doubles as healer)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="parisa" enablePopover /> (30
                  ATK SPD, +30% Normal Attack Damage, single target)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="reinier" enablePopover />{' '}
                  (4% ATK)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="rowan" enablePopover /> (320
                  Energy, uptime tied to ultimate – doubles as healer - decent
                  with carries such as Odie or Marilee who deal damage with
                  their ultimate, not so good with Cecia. Not as good for Snow
                  Stomper since ultimates have less value here)
                </li>
              </ul>
            </div>
          </div>
          <div className="dream-role">
            <h5 className="dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> DPS Carry
            </h5>
            <div className="dream-inside">
              <p className="with-line gold">
                <strong className="gold">Best in slot</strong>
              </p>
              <p>
                Anyone who has great sustained single target damage output.
                Added self-sustain is a bonus.
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="marilee" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="korin" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="temesia" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="rhys" enablePopover />
                </li>
              </ul>
              <p className="with-line silver">
                <strong className="silver">Viable alternatives</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="dionel" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="vala" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="walker" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="bryon" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cassadee" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cecia" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="seth" enablePopover />
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="shakir" enablePopover />
                </li>
              </ul>
            </div>
          </div>
          <div className="dream-role">
            <h5 className="healer">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Healer
              (optional)
            </h5>
            <div className="dream-inside">
              <p className="with-line gold">
                <strong className="gold">Best in slot</strong>
              </p>
              <p>
                Anyone who can keep your team alive while also increasing team
                damage output. You don’t really need a dedicated healer here,
                but if you must bring one...
              </p>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="reinier" enablePopover />{' '}
                  (many don’t think of him as a healer, but he gives life drain
                  to a single ally. Coincidentally, Snow Stomper focuses most of
                  his damage on your dps carry with the highest damage dealt,
                  usually Marilee, so Reinier can keep them alive throughout the
                  fight)
                </li>
              </ul>
              <p className="with-line silver">
                <strong className="silver">Viable alternatives</strong>
              </p>
              <ul>
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="smokey-and-meerky"
                    enablePopover
                  />{' '}
                  (despite being interrupted by Snow Stomper’s blizzard, Smokey
                  still manages to get some bursts of healing out)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover />{' '}
                  (her ultimate can be used in between blizzards, but she is not
                  a good Dream Realm character overall)
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover />{' '}
                  (due to Damian’s special mechanic where he summons his toy
                  carriage to heal, he can freely heal your team without getting
                  interrupted by Yeti’s blizzard)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamSnow;

export const Head: React.FC = () => (
  <Seo
    title="Snow Stomper | AFK Journey | Prydwen Institute"
    description="Dream Realm Snow Stomper guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
